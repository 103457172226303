

$(function() {
	var loadCount = 0, 
	imgLength = $("img").size(); 

	$("img").each(function() {
		var src = $(this).attr("src");
		$("<img>")
				.attr("src", src)
				.load(function() {
						loadCount++; 
				});
	});

	var timer = setInterval(function() { 
		$(".loadingBar").css({
				"width": (loadCount / imgLength) * 100 + "%" 
		});
		if((loadCount / imgLength) * 100 == 100){ 
			clearInterval(timer);
			$(".loadingBar").delay(500).animate({
					"opacity": 0
			}, 200);
			$("#loadbg").delay(800).fadeOut(500);({
			}, 1000);
		}
	}, 5);
});


const dropdownBtn = document.querySelectorAll("[data-dropdown-btn]");
const dropdownItems = document.querySelectorAll("[data-dropdown-item]");

if( dropdownBtn ) {
	for (let i = 0; i < dropdownBtn.length; i++) {
		dropdownBtn[i].addEventListener("click", function(e) {
			onClickDropDownButton(e);
		});
	}
	for (let i = 0; i < dropdownItems.length; i++) {
		dropdownItems[i].addEventListener("click", function(e) {
			onClickDropdownMenuItem(e);
		});
	}

	function onClickDropDownButton(event) {
		const dropdown = event.target.closest("[data-dropdown]");
		const rect = event.target.getBoundingClientRect();
		
		const dropdownLayer = dropdown.querySelector("[data-dropdown-layer]");
		const dropdownMenu = dropdown.querySelector("[data-dropdown-menu]");
	
		// dropdownMenu.style.top = `${rect.top + rect.height - 1}px`;
		// dropdownMenu.style.left = `${rect.left}px`;
		// dropdownMenu.style.minWidth = `${rect.width}px`;
	
		dropdownLayer.classList.add("dropdown__menu-layer--show");
	}
	
	function onClickDropdownMenuItem(event) {
		// const value = event.currentTarget.dataset.value;
		// const label = event.currentTarget.innerText;
	
		// const dropdownLayer = event.target.closest("[data-dropdown-layer]");
		// const dropdown = event.target.closest("[data-dropdown]");
		// const button = dropdown.querySelector("[data-dropdown-btn]");
		// button.innerText = label;
		
		// const items = dropdown.querySelectorAll("[data-dropdown-item]");
		// for (let i = 0; i < items.length; i++) {
		// 	const item = items[i];
		// 	item.classList.remove("dropdown__item--active");
		// }
		event.currentTarget.classList.add("dropdown__item--active");
		dropdownLayer.classList.remove("dropdown__menu-layer--show");
	}

	//閉じる
	document.addEventListener('click', (e) => {
		if(!e.target.closest('.dropdown')) {
			const dropdownLayers = document.querySelectorAll("[data-dropdown-layer]");
			for (let i = 0; i < dropdownLayers.length; i++) {

				dropdownLayers[i].classList.remove("dropdown__menu-layer--show");
				console.log(dropdownLayers[i].classList);
			}
		}
	})	
}



